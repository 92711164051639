<template>
  <div class="company-verify-container">
    <!-- 背景视频 -->
    <video
      :style="fixStyle"
      class="video-box"
      autoplay
      loop
      muted
    >
      <source
        src="../assets/video/ccci.mp4"
        type="video/mp4"
      />
    </video>

    <!-- 语言切换 -->
    <div class="language-box">
      <el-select
        v-model="lang.value"
        popper-class="public-select-dropdown"
        @change="langChange"
      >
        <el-option
          v-for="item in lang.options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
    </div>

    <!-- 内容区域 -->
    <div class="content-box">
      <!-- logo、商会名称 -->
      <div class="chamber-name-box">
        <div class="chamber-left">
          <img src="@/assets/xiangqi_imgs/logo.png" />
        </div>
        <div class="chamber-right">
          <p>{{ $t('word2001') }}</p>
          <p>{{ $t('word2003') }}</p>
        </div>
      </div>

      <!-- 表单区域 -->
      <div class="form-box">
        <el-form
          :model="form"
          :rules="formRules"
          ref="formRef"
        >
          <!-- 公司名称 -->
          <el-form-item
            prop="companyName"
            :error="companyNameError"
          >
            <el-input
              v-model="form.companyName"
              prefix-icon="iconfont icon-gongsimingcheng left-icon"
              :placeholder="$t('word1037')"
              @blur="blurCompanyName(form.companyName)"
            >
            </el-input>
          </el-form-item>
          <!-- 公司地址 -->
          <el-form-item
            prop="companyAddress"
            :error="companyAddressError"
          >
            <el-input
              v-model="form.companyAddress"
              prefix-icon="iconfont icon-gongsidizhi left-icon"
              :placeholder="$t('word1038')"
              @blur="blurCompanyAddress(form.companyAddress)"
            >
            </el-input>
          </el-form-item>
          <!-- 公司电话 -->
          <el-form-item prop="companyTelephone">
            <el-input
              v-model="form.companyTelephone"
              prefix-icon="iconfont icon-dianhua left-icon"
              :placeholder="$t('word1039')"
              @input="form.companyTelephone = form.companyTelephone.replace(/[^0-9\+\(\)\-]/g, '')"
            >
            </el-input>
          </el-form-item>
          <!-- 在任职位 -->
          <el-form-item
            prop="jobTitle"
            :error="jobTitleError"
          >
            <el-input
              v-model="form.jobTitle"
              prefix-icon="iconfont icon-suoshuzhiwei left-icon"
              :placeholder="$t('word1040')"
              @blur="blurJobTitle(form.jobTitle)"
            >
            </el-input>
          </el-form-item>
          <!-- 营业执照 -->
          <el-form-item
            prop="businessLicense"
            class="img-upload-item"
          >
            <div class="img-upload-box">
              <div class="upload-tip">{{ $t('word1041') }}</div>
              <el-upload
                class="upload-box"
                :class="{ hide: hideBusiness }"
                ref="businessRef"
                :action="uploadURL"
                :accept="imgAccept"
                list-type="picture-card"
                :auto-upload="true"
                :limit="1"
                :before-upload="businessBeforeUpload"
                :on-success="businessSuccess"
              >
                <i
                  slot="default"
                  class="el-icon-plus"
                ></i>
                <div
                  slot="file"
                  slot-scope="{file}"
                >
                  <img
                    class="el-upload-list__item-thumbnail"
                    :src="file.url"
                  />
                  <span class="el-upload-list__item-actions">
                    <span
                      class="el-upload-list__item-preview"
                      @click="businessPreview(file)"
                    >
                      <i class="el-icon-zoom-in"></i>
                    </span>
                    <span
                      class="el-upload-list__item-delete"
                      @click="businessRemove(file)"
                    >
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </div>
              </el-upload>
              <div class="upload-tip">{{ $t('FileSize') }}</div>
            </div>
          </el-form-item>
          <!-- 税务证 -->
          <el-form-item
            prop="taxCertificate"
            class="img-upload-item"
          >
            <div class="img-upload-box">
              <div class="upload-tip">{{ $t('word1043') }}</div>
              <el-upload
                class="upload-box"
                :class="{ hide: hideTax }"
                ref="taxRef"
                :action="uploadURL"
                :accept="imgAccept"
                list-type="picture-card"
                :auto-upload="true"
                :limit="1"
                :before-upload="taxBeforeUpload"
                :on-success="taxSuccess"
              >
                <i
                  slot="default"
                  class="el-icon-plus"
                ></i>
                <div
                  slot="file"
                  slot-scope="{file}"
                >
                  <img
                    class="el-upload-list__item-thumbnail"
                    :src="file.url"
                  />
                  <span class="el-upload-list__item-actions">
                    <span
                      class="el-upload-list__item-preview"
                      @click="taxPreview(file)"
                    >
                      <i class="el-icon-zoom-in"></i>
                    </span>
                    <span
                      class="el-upload-list__item-delete"
                      @click="taxRemove(file)"
                    >
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </div>
              </el-upload>
              <div class="upload-tip">{{ $t('FileSize') }}</div>
            </div>
          </el-form-item>
          <!-- 按钮 -->
          <el-form-item class="btn-item">
            <el-button
              class="btn-box"
              @click="submitForm"
            >{{ $t('word1044') }}</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <!-- 营业执照预览 -->
    <el-dialog
      :title="$t('word1046')"
      :visible.sync="businessVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="800px"
      class="dialog-wrap"
    >
      <img
        width="100%"
        :src="businessImageUrl"
      />
    </el-dialog>

    <!-- 税务证预览 -->
    <el-dialog
      :title="$t('word1046')"
      :visible.sync="taxVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="800px"
      class="dialog-wrap"
    >
      <img
        width="100%"
        :src="taxImageUrl"
      />
    </el-dialog>
  </div>
</template>

<script>
import mixin from '@/mixins/mixin.js'
import { updateIdentification } from '@/api/api.js'

export default {
  name: 'CompanyVerify',
  mixins: [mixin],
  data() {
    // 营业执照
    const checkBusinessLicense = (rule, value, callback) => {
      if (!this.uploadBusiness.length) {
        callback(new Error(this.$t('word1047')))
      } else {
        callback()
      }
    }
    // 税务证
    const checkTaxCertificate = (rule, value, callback) => {
      if (!this.uploadTax.length) {
        callback(new Error(this.$t('word1048')))
      } else {
        callback()
      }
    }

    return {
      fixStyle: '',
      // 语言数据
      lang: {
        options: [
          {
            value: 'zh-CN',
            label: '简体中文'
          },
          {
            value: 'en-US',
            label: 'English'
          },
          {
            value: 'Thai',
            label: 'ภาษาไทย'
          }
          /* {
            value: 'bahasa-Indonesia',
            label: 'Bahasa Indonesia'
          },
          {
            value: 'Malay',
            label: 'Bahasa Melayu'
          },

          {
            value: 'Vietnamese',
            label: 'Tiếng Việt'
          },
          {
            value: 'Burmese',
            label: 'ဗမာစာ'
          },
          {
            value: 'Lao',
            label: 'ພາສາລາວ'
          },
          {
            value: 'Khmer',
            label: 'ភាសាខ្មែរ'
          },
          {
            value: 'Filipino',
            label: 'Wikang Filipino'
          },
          {
            value: 'Portuguese',
            label: 'Português'
          }, */
        ],
        value: sessionStorage.getItem('locale') ? sessionStorage.getItem('locale') : 'zh-CN'
      },
      // 图片上传
      uploadURL: 'https://test.attorneyathena.com/saas-api/athena-consumer/AppUploadDO/imgUpload',
      imgAccept: '.jpeg,.jpg,.png,.bmp,.tiff',
      uploadBusiness: [], // 上传营业执照
      hideBusiness: false, // 控制 + 号显示与隐藏
      businessVisible: false,
      businessImageUrl: '',
      uploadTax: [], // 上传税务证
      hideTax: false, // 控制 + 号显示与隐藏
      taxVisible: false,
      taxImageUrl: '',

      // 表单对象
      form: {
        companyName: '',
        companyAddress: '',
        companyTelephone: '',
        jobTitle: ''
      },
      // 表单校验对象
      formRules: {
        companyName: [{ required: true, message: this.$t('word1049'), trigger: 'blur' }],
        companyAddress: [{ required: true, message: this.$t('word1050'), trigger: 'blur' }],
        companyTelephone: [{ required: true, message: this.$t('word1051'), trigger: 'blur' }],
        jobTitle: [{ required: true, message: this.$t('word1052'), trigger: 'blur' }],
        businessLicense: [{ required: true, validator: checkBusinessLicense, trigger: 'change' }],
        taxCertificate: [{ required: true, validator: checkTaxCertificate, trigger: 'change' }]
      },
      companyNameError: '', // 公司名称错误提示
      companyAddressError: '', // 公司地址错误提示
      jobTitleError: '' // 在任职位错误提示
    }
  },
  mounted() {
    this.synchronizeWindowSize()
  },
  methods: {
    // 主要是检测窗口缩放视频也同步大小
    synchronizeWindowSize() {
      window.onresize = () => {
        const windowWidth = document.body.clientWidth
        const windowHeight = document.body.clientHeight
        const windowAspectRatio = windowHeight / windowWidth
        let videoWidth
        let videoHeight
        if (windowAspectRatio < 0.5625) {
          videoWidth = windowWidth
          videoHeight = videoWidth * 0.5625
          this.fixStyle = {
            height: windowWidth * 0.5625 + 'px',
            width: windowWidth + 'px',
            'margin-bottom': (windowHeight - videoHeight) / 2 + 'px',
            'margin-left': 'initial'
          }
        } else {
          videoHeight = windowHeight
          videoWidth = videoHeight / 0.5625
          this.fixStyle = {
            height: windowHeight + 'px',
            width: windowHeight / 0.5625 + 'px',
            'margin-left': (windowWidth - videoWidth) / 2 + 'px',
            'margin-bottom': 'initial'
          }
        }
      }
      window.onresize()
    },

    // 公司名称失焦
    blurCompanyName(companyName) {
      this.companyNameError = ''
      if (!companyName.trim()) {
        // 此处 this.$nextTick 是解决：两次相同的错误提示，第二次不显示问题
        this.$nextTick(() => {
          this.companyNameError = this.$t('word1049')
        })
      }
    },
    // 公司地址失焦
    blurCompanyAddress(companyAddress) {
      this.companyAddressError = ''
      if (!companyAddress.trim()) {
        // 此处 this.$nextTick 是解决：两次相同的错误提示，第二次不显示问题
        this.$nextTick(() => {
          this.companyAddressError = this.$t('word1050')
        })
      }
    },
    // 在任职位失焦
    blurJobTitle(jobTitle) {
      this.jobTitleError = ''
      if (!jobTitle.trim()) {
        // 此处 this.$nextTick 是解决：两次相同的错误提示，第二次不显示问题
        this.$nextTick(() => {
          this.jobTitleError = this.$t('word1052')
        })
      }
    },

    // 营业执照
    // 图片上传之前判断图片大小
    businessBeforeUpload(file) {
      const fileSuffix = file.name.substring(file.name.lastIndexOf('.'))
      const suffixList = this.imgAccept.split(',')
      if (suffixList.indexOf(fileSuffix) === -1) {
        this.$message.error(this.$t('ImgOnly'))
        return false
      }

      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error(this.$t('PictureSize2MBTip'))
        return false
      }
    },
    // 图片上传成功
    businessSuccess(response, file, fileList) {
      if (fileList.every(item => item.status == 'success')) {
        const arr = []
        fileList.forEach(ele => {
          arr.push(ele.response.data[0])
        })
        this.uploadBusiness = [...arr]
        this.$message.success({ message: this.$t('UploadSuccess') })

        // 隐藏 + 号
        this.hideBusiness = fileList.length >= 1
      }
    },
    // 图片预览
    businessPreview(file) {
      this.businessImageUrl = file.url
      this.businessVisible = true
    },
    // 图片删除
    businessRemove(file) {
      this.$message.success({ message: this.$t('word1053') })
      const fileList = this.$refs.businessRef.uploadFiles
      // 删除
      if (fileList.length) fileList.splice(0, 1)
      this.uploadBusiness = []
      // 显示 + 号
      this.hideBusiness = false
    },

    // 税务证
    // 图片上传之前判断图片大小
    taxBeforeUpload(file) {
      const fileSuffix = file.name.substring(file.name.lastIndexOf('.'))
      const suffixList = this.imgAccept.split(',')
      if (suffixList.indexOf(fileSuffix) === -1) {
        this.$message.error(this.$t('ImgOnly'))
        return false
      }

      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error(this.$t('PictureSize2MBTip'))
        return false
      }
    },
    // 图片上传成功
    taxSuccess(response, file, fileList) {
      if (fileList.every(item => item.status == 'success')) {
        const arr = []
        fileList.forEach(ele => {
          arr.push(ele.response.data[0])
        })
        this.uploadTax = [...arr]
        this.$message.success({ message: this.$t('UploadSuccess') })

        // 隐藏 + 号
        this.hideTax = fileList.length >= 1
      }
    },
    // 图片预览
    taxPreview(file) {
      this.taxImageUrl = file.url
      this.taxVisible = true
    },
    // 图片删除
    taxRemove(file) {
      this.$message.success({ message: this.$t('word1053') })
      const fileList = this.$refs.taxRef.uploadFiles
      // 删除
      if (fileList.length) fileList.splice(0, 1)
      this.uploadTax = []
      // 显示 + 号
      this.hideTax = false
    },

    // 语言切换
    langChange(value) {
      const lang = value === 'zh-CN' ? 'zh_cn' : value === 'en-US' ? 'en' : value
      this.setCookie('org.springframework.web.servlet.i18n.CookieLocaleResolver.LOCALE', lang)
      sessionStorage.setItem('locale', value)
      sessionStorage.setItem('changeChange', 'yes')
      window.location.reload() // 刷新页面
    },

    // 提交
    submitForm() {
      this.$refs.formRef.validate(valid => {
        if (valid) {
          const { companyName, companyAddress, companyTelephone, jobTitle } = this.form
          // 判断公司名称是否为空
          if (!companyName.trim()) {
            this.companyNameError = ''
            // 此处 this.$nextTick 是解决：两次相同的错误提示，第二次不显示问题
            this.$nextTick(() => {
              this.companyNameError = this.$t('word1049')
            })
            this.$message.error({ message: this.$t('word1049'), duration: 3000 })
            return false
          }
          // 判断公司地址是否为空
          if (!companyAddress.trim()) {
            this.companyAddressError = ''
            // 此处 this.$nextTick 是解决：两次相同的错误提示，第二次不显示问题
            this.$nextTick(() => {
              this.companyAddressError = this.$t('word1050')
            })
            this.$message.error({ message: this.$t('word1050'), duration: 3000 })
            return false
          }
          // 判断在任职位是否为空
          if (!jobTitle.trim()) {
            this.jobTitleError = ''
            // 此处 this.$nextTick 是解决：两次相同的错误提示，第二次不显示问题
            this.$nextTick(() => {
              this.jobTitleError = this.$t('word1052')
            })
            this.$message.error({ message: this.$t('word1052'), duration: 3000 })
            return false
          }

          const params = {
            userId: this.$route.query.id,
            companyName: companyName.trim(),
            companyAddress: companyAddress.trim(),
            companyPhone: companyTelephone,
            positionTitle: jobTitle.trim(),
            businessLicense: this.uploadBusiness[0],
            taxCertificate: this.uploadTax[0]
          }
          updateIdentification(params).then(res => {
            if (res.code === 200) {
              this.$message.success({ message: this.$t('word1054'), duration: 1500 })
              this.$router.push({ name: 'login' })
            }
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.company-verify-container {
  position: relative;
  height: 100%;
  overflow: hidden;

  // 背景视频
  .video-box {
    position: absolute;
    z-index: 0;
  }

  // 语言切换
  .language-box {
    position: absolute;
    top: 40px;
    left: 80px;
    z-index: 2;
    width: 160px;
    ::v-deep .el-select {
      .el-input__inner {
        background-color: rgba(255, 255, 255, 0.8);
        color: #43403d;
      }
      .el-select__caret {
        color: #43403d;
      }
    }
  }

  // 内容区域
  .content-box {
    position: absolute;
    left: 80px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    // logo、商会名称
    .chamber-name-box {
      display: flex;
      align-items: center;
      width: 700px;
      margin-bottom: 30px;
      & > .chamber-left {
        width: 100px;
        height: 100px;
        margin-right: 10px;
        & > img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      & > .chamber-right {
        flex: 1;
        width: 0;
        flex-wrap: wrap;
        color: #ffffff;
        font-size: 20px;
        & > p:first-child {
          margin-bottom: 10px;
        }
      }
    }

    // 表单区域
    .form-box {
      width: 460px;
      height: 400px;
      padding-right: 10px;
      overflow-x: hidden;
      overflow-y: auto;

      // 滚动条样式
      &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, 0.8);
        border-radius: 5px;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }

      ::v-deep .el-form {
        .el-form-item {
          margin-bottom: 30px;
        }
        .el-input__inner {
          background-color: transparent;
          color: #ffffff;
          border: none;
          border-bottom: 1px solid #ffffff;
          border-radius: 0;
        }
        .el-input--prefix .el-input__inner {
          padding-left: 40px;
        }
        .left-icon {
          color: #ffffff;
          font-size: 24px;
        }
        // 图片上传
        .img-upload-item {
          .img-upload-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            .upload-box {
              line-height: 10px;
            }
            .hide .el-upload--picture-card {
              display: none;
            }
            .upload-tip {
              color: #ffffff;
            }
            .el-upload--picture-card {
              background-color: #ffffff;
              border: 1px dashed #939699;
              width: 80px;
              height: 80px;
              line-height: 80px;
            }
            .el-upload-list--picture-card .el-upload-list__item {
              width: 82px;
              height: 82px;
              margin-bottom: 0;
              & > div {
                height: 100%;
              }
            }
          }
        }
        // 按钮
        .btn-item {
          margin-bottom: 0;
          .el-form-item__content {
            text-align: center;
          }
          .btn-box {
            border: none;
            background: rgba(140, 51, 38, 0.8);
            padding: 12px 40px;
            color: #ffffff;
          }
        }
      }
    }
  }
}
</style>